<template>
	<div>
		<b-card title="Current users">
		<b-list-group v-for="item in people" :key="item.id" variant="bg-transparent">
			<b-list-group-item>
				{{ item }}
			</b-list-group-item>
		</b-list-group>
		</b-card>
	</div>
</template>

<script>
import firebase from '@/firebase.js'

export default {
	name: 'CurrentUsers',
	data()
	{
		return {
			collectionData: [],
			people: []
		}
	},
	async mounted()
	{
		firebase.db.collection("showState")
		.onSnapshot((snapshot) =>
		{
			this.collectionData = []
			this.people = []
			snapshot.forEach((doc) =>
			{
				this.collectionData.push(doc.data())
				
				this.people = doc.data()['people']
			})
		})
	},
	props:
	{

	}
}
</script>

