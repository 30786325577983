<template>
	<div>
		<b-card title="Show state">
			<ol class="newState">
				<div v-for="item in states" :key="item.id">
					<li>
						<b-button size="lg" :variant="stateID == item.id ? 'primary' : 'secondary'" v-on:click="setState(item)">
							<div v-if="item.data()['stateType'] == 'QUESTION' || item.data()['stateType'] == 'MULTIQUESTION'">
								<span v-if="item.data()['questionLabel']">
									{{ item.data()['questionLabel'] }}
								</span>
							</div>

							<span v-if="item.data()['stateType'] == 'START'">Start</span>
							<span v-if="item.data()['stateType'] == 'PRIVILEGE_GAME'">Privilege game</span>
							<span v-if="item.data()['stateType'] == 'GIVEUP_PRIVILEGE'">Release privilege</span>
							<span v-if="item.data()['stateType'] == 'ANIMATED_LOGO'">Animated logo</span>
							<span v-if="item.data()['stateType'] == 'END'">End</span>
						</b-button>
					</li>
				</div>
			</ol>
		</b-card>
	</div>
</template>

<script>
import firebase from '@/firebase.js';

export default {
	name: "SetShowState",
	data()
	{
		return {
			newState: 'START',
			states: [],
		}
	},
	props:
	{
		showState:
		{
			type: String
		},
		stateID:
		{
			type: String,
			default: 'xxx'
		},
		showConfig:
		{
			type: Object
		},
	},
	methods:
	{
		setState: async function(newState)
		{
			var showID = await firebase.getShowStage()

			firebase.db.collection("showState").doc(showID[0]).set({
				stateID: newState.id
			}, { merge: true })
		}
	},
	mounted()
	{
		firebase.db.collection("showStates")
		.where("isEnabled","==",true)
		.orderBy('order')
		.onSnapshot((snapshot) =>
		{
			this.states = []
			snapshot.forEach((doc) =>
			{
				this.states.push(doc)
				console.log(doc.data())
			})
		})
	},
}
</script>

<style lang="css">
.newState
{

}

.newState li
{
	list-style-type: none;
}

.newState button
{
	width: 400px;
}

.newState li
{
	padding: 10px;
}

.selected
{
	background-color: green;
	color: #ffffff;
}
</style>
