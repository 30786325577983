<template>
	<div>
		<b-card title="Debug tools">
			<b-list-group>
				<b-list-group-item>
					<b-button pill size="lg" variant="secondary" v-on:click="clearUsername()">Clear username</b-button>
				</b-list-group-item>
			</b-list-group>
		</b-card>
	</div>
</template>

<script>
import Vue from 'vue'
// import Firebase from '../firebase.js';

export default {
	'name': 'DebugTools',
	methods:
	{
		clearUsername: function()
		{
			var toastOptions = {
				duration: 1000,
				type: "error",
				theme: "bubble"
			}

			Vue.toasted.show("Username cleared", toastOptions);
			this.$cookies.remove("username")
		},
	}

}
</script>
