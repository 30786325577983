<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<CurrentUsers />
			</div>
			<!-- <div class="col">
				<GraphResults v-bind:chartData="chartData" />
			</div> -->
			<div class="col">
				<SetShowState v-bind:stateID="stateID" v-bind:showConfig="showConfig" />	
			</div>
		</div>
		<div class="row" style="margin-top: 30px">
			<div class="col">
				<DebugTools />
			</div>
			<div class="col">

			</div>
		</div>
	</div>
</template>

<script>
import firebase from '@/firebase.js'

import CurrentUsers from '@/components/CurrentUsers.vue'
// import GraphResults from '@/components/GraphResults.vue'
import SetShowState from '@/components/SetShowState.vue'
import DebugTools from '@/components/DebugTools.vue'

export default {
	name: 'AdminHome',
	components:
	{
		CurrentUsers,
		// GraphResults,
		SetShowState,
		DebugTools
	},
	data()
	{
		return {
			showData: [],
			stateID: 'xxxx',
			showConfig: {},
			chartData: {
				labels: ['January', 'February'],
				datasets: [
				{
					label: 'Data One',
					backgroundColor: '#f87979',
					data: [40, 20]
				}
				]
			}
		}
	},
	async mounted()
	{
		// this.showConfig = await firebase.getShowConfig("QLwnD111reQZIbZnK09l")
		// console.log(this.showConfig)

		firebase.db.collection("showState")
		.onSnapshot((snapshot) =>
		{
			this.showData = []
			snapshot.forEach((doc) =>
			{
				this.showData = doc.data()
				this.stateID  = this.showData['stateID']
			})
		})
	},
}
</script>